import logo from './logo.svg';
import './App.css';
import TipCalculator from './components/TipCalculator/TipCalculator';

function App() {
  return (
   <TipCalculator/>
  );
}

export default App;
